
    import * as Sentry from '@sentry/vue'
    import { Severity } from '@sentry/vue'
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { getModule } from 'vuex-module-decorators'

    import { AuthError, LoginError } from '@/errors/auth-errors'

    import Users from '@/store/modules/auth'
    import Notifications from '@/lib/notifications'

    @Component
    export default class PageLogin extends Vue {
        serverErrors: Array<string> = []
        loading = false

        // region Field mappings

        get email (): string {
            return getModule(Users, this.$store).credentials.email
        }

        set email (value: string) {
            getModule(Users, this.$store).setEmail(value)
        }

        get password (): string {
            return getModule(Users, this.$store).credentials.password
        }

        set password (value: string) {
            getModule(Users, this.$store).setPassword(value)
        }

        // endregion

        async login (): Promise<void> {
            this.serverErrors = []

            try {
                this.loading = true
                await getModule(Users, this.$store).login()
                Notifications.success(this.$t('login.logged') as string)
                this.$router.push({ name: 'messages.list' })
            } catch (e) {
                Notifications.error(this.$t('login.unknown_login_error') as string)
                if (e instanceof LoginError) {
                    Sentry.captureException(e, {
                        level: Severity.Error
                    })

                    this.serverErrors = e.messages
                } else if (e instanceof AuthError) {
                    Sentry.captureException(e, {
                        level: Severity.Error
                    })

                    this.serverErrors = [
                        this.$t('errors.login') as string
                    ]
                } else {
                    throw e
                }
            } finally {
                this.loading = false
            }
        }
    }
